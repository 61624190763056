import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Buildings.scss';

// The current static svg item path elemeents and render image
import image from '../views/building.jpg';
import buildings from '../views/building.json';

export default function Buildings() {
  const { attributes, children } = buildings;

  const [currentBuilding, setCurrentBuilding] = useState(null);

  // Map all the objects into its corresponding SVG path in the render
  const buildingPolygons = children.map((item, index) => {
    const { id, d } = item.attributes;
    return (
      <Link
        to={id === 'alten' ? '/byggnad/alten/2' : '/byggnad/basen/3'}
        data-testid={`building-link`}
        key={index}
        onMouseEnter={() => {
          setCurrentBuilding(id);
        }}
        onMouseLeave={() => {
          setCurrentBuilding(null);
        }}
        className={currentBuilding === id ? 'active' : null}
      >
        <path id={id} d={d} data-testid="building-svg"></path>
      </Link>
    );
  });

  return (
    <>
      <div className="Buildings">
        <svg
          data-testid="buildings-svg"
          viewBox={attributes.viewBox}
          preserveAspectRatio={attributes.preserveAspectRatio || 'xMidYMid'}
        >
          {buildingPolygons}
        </svg>
        <img src={image} alt="Rendering" data-testid="render-view-image" />
      </div>
      <div className="buildings-helper">
        <h2>
          Välj hus:
          <Link
            to="/byggnad/alten/2"
            onMouseEnter={() => {
              setCurrentBuilding('alten');
            }}
            onMouseLeave={() => {
              setCurrentBuilding(null);
            }}
            className={
              currentBuilding === 'alten'
                ? 'building-anchor active'
                : 'building-anchor'
            }
          >
            Alten
          </Link>
          <Link
            to="/byggnad/basen/4"
            onMouseEnter={() => {
              setCurrentBuilding('basen');
            }}
            onMouseLeave={() => {
              setCurrentBuilding(null);
            }}
            className={
              currentBuilding === 'basen'
                ? 'building-anchor active'
                : 'building-anchor'
            }
          >
            Basen
          </Link>
        </h2>
      </div>
    </>
  );
}
