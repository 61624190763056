import React from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { formatNumber } from '../helpers/helpers';
import 'tippy.js/dist/tippy.css';
import './ObjectItem.scss';

export function ObjectTooltip(props) {
  const { itemData } = props;
  const price = itemData.economy.price
    ? formatNumber(itemData.economy.price)
    : null;
  const fee = itemData.economy.fee ? formatNumber(itemData.economy.fee) : null;
  return (
    <div className="ObjectTooltip" data-testid="object-item-tooltip">
      <h3>{itemData.apartmentNumber}</h3>
      <p>
        Status: <strong>{itemData.status}</strong>
        <br />
        Antal rum: <strong>{itemData.rooms} rum & kök</strong>
        <br />
        Pris:{' '}
        <strong>{price ? `${price} ${itemData.economy.currency}` : '-'}</strong>
        <br />
        Avgift:{' '}
        <strong>{fee ? `${fee} ${itemData.economy.currency}` : '-'}</strong>
        <br />
        Storlek: <strong>{itemData.area.size} m²</strong>
        <br />
        Våning: <strong>{itemData.floor}</strong>
      </p>
    </div>
  );
}

export default function ObjectItem(props) {
  const {
    name,
    attributes,
    itemData,
    handleActiceObject,
    activeObjectId,
  } = props;

  if (!itemData) {
    return null;
  }

  const status = itemData.status;
  let classes = attributes.class || '';

  if (status === 'Till salu') {
    classes += ' for-sale';
  } else if (status === 'Intagen') {
    classes += ' sold-not-admitted';
  } else if (status === 'Bokad') {
    classes += ' reserved';
  } else if (status === 'Såld') {
    classes += ' sold';
  }

  if (activeObjectId === itemData.fasadId) {
    classes += ' active';
  }

  if (name === 'path') {
    return (
      <Tippy
        trigger="mouseenter"
        content={<ObjectTooltip data-test="" itemData={itemData} />}
        theme="kvartetten"
        followCursor={true}
        plugins={[followCursor]}
      >
        <Link to={`/objekt/${itemData.fasadId}`} data-testid="object-link">
          <path
            id={attributes.id}
            className={classes}
            d={attributes.d}
            data-name={attributes['data-name']}
            data-testid="object-item-svg"
            onMouseEnter={() => {
              handleActiceObject(itemData.fasadId);
            }}
            onMouseLeave={() => {
              handleActiceObject(null);
            }}
          ></path>
        </Link>
      </Tippy>
    );
  } else if (name === 'polygon') {
    return (
      <Tippy
        trigger="mouseenter"
        content={<ObjectTooltip data-test="" itemData={itemData} />}
      >
        <Link to={`/objekt/${itemData.fasadId}`} data-testid="object-link">
          <polygon
            id={attributes.id}
            className={classes}
            points={attributes.points}
            data-testid="object-item-svg"
          ></polygon>
        </Link>
      </Tippy>
    );
  }
  if (name === 'rect') {
    return (
      <Tippy
        trigger="mouseenter"
        content={<ObjectTooltip itemData={itemData} />}
      >
        <Link to={`/objekt/${itemData.fasadId}`} data-testid="object-link">
          <rect
            id={attributes.id}
            className={classes}
            height={attributes.height}
            width={attributes.width}
            x={attributes.x}
            y={attributes.y}
            data-testid="object-item-svg"
          ></rect>
        </Link>
      </Tippy>
    );
  } else if (name === 'path') {
    return null; // At the moment we do not support path, might be needed in the future
  } else if (name === 'defs') {
    return null;
  } else {
    return null;
  }
}
