import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import SingleObject from './components/SingleObject';
import SceneContainer from './components/Scene';
import Buildings from './components/Buildings';
import Navigation from './components/Navigation';
import LoadingIndicator from './components/LoadingIndicator';
import NoMatch from './components/NoMatch';
import ObjectTable from './components/ObjectTable';
import { loadObjects } from './helpers/helpers';
import ModalProvider from './contexts/ModalContext';

import './App.scss';
import allowedRoutes from './data/allowedRoutes.json';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-183931554-1', {
  testMode: process.env.NODE_ENV === 'test',
});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchObjects = async () => {
      const json = await loadObjects();
      setItems(json);
      setLoading(false);
    };
    fetchObjects();
  }, []);

  return (
    <Router>
      <ModalProvider>
        <div className="App">
          {loading && <LoadingIndicator />}
          {!loading && (
            <>
              <Navigation />
              <Switch>
                <Route
                  path="/"
                  exact
                  render={(props) => {
                    return (
                      <div className="main-wrapper">
                        <Buildings />
                      </div>
                    );
                  }}
                />
                <Route
                  path="/byggnad/:building/:sceneIndex"
                  render={(props) => {
                    const { building, sceneIndex } = props.match.params;
                    return allowedRoutes.buildings.includes(building) &&
                      allowedRoutes.scenes.includes(sceneIndex) ? (
                      <div className="main-wrapper">
                        <SceneContainer items={items} />
                      </div>
                    ) : (
                      <NoMatch />
                    );
                  }}
                />
                <Route
                  path="/prislista/"
                  render={(props) => {
                    return (
                      <ObjectTable items={items} totalItems={items.length} />
                    );
                  }}
                />
                <Route
                  path="/objekt/:fasadId"
                  render={(props) => {
                    return (
                      <SingleObject
                        items={items}
                        fasadId={props.match.params.fasadId}
                      />
                    );
                  }}
                />
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </>
          )}
        </div>
      </ModalProvider>
    </Router>
  );
}

export default App;
