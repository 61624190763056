import React from 'react';
import { Link } from 'react-router-dom';

export default function NoMatch() {
  return (
    <div className="no-match">
      <h1>Error 404 - Hittade ej sidan</h1>
      <p>
        Tillbaka till <Link to="/">lägenhetsväljaren</Link>.
      </p>
    </div>
  );
}
