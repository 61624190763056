import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './BackLink.scss';

export default function BackLink() {
  let history = useHistory();

  return (
    <Link
      to="#"
      onClick={(event) => {
        event.preventDefault();
        history.goBack();
      }}
      className="button button-goback"
    >
      {'< Tillbaka'}
    </Link>
  );
}
