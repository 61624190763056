import alten1Svg from '../views/alten_1.json';
import alten2Svg from '../views/alten_2.json';
import alten3Svg from '../views/alten_3.json';
import alten4Svg from '../views/alten_4.json';
import basen1Svg from '../views/basen_1.json';
import basen2Svg from '../views/basen_2.json';
import basen3Svg from '../views/basen_3.json';
import basen4Svg from '../views/basen_4.json';

export const loadObjects = async () => {
  //const url = `${corsProxy}${fasadUrl}`;
  const url = 'https://wallinbostad.se/api-beskrivning/';

  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'text/plain',
        Origin: window.location.origin,
      },
    });
    const text = await response.text();
    const xmlDoc = await new DOMParser().parseFromString(text, 'text/xml');
    return convertXML2Json(xmlDoc);
  } catch (error) {
    console.error(error);
  }
};

function getHumanReadableItemStatus(status = '') {
  if (status === 'Försäljning') {
    return 'Till salu';
  } else if (status === 'Reserverad') {
    return 'Bokad';
  } else if (status === 'Såld' || status === 'Såld ej tillträdd') {
    return 'Såld';
  } else if (status === 'Intagen' || status === 'Ej till salu') {
    return 'Senare etapp';
  } else {
    return status;
  }
}

function convertXML2Json(xmlDocument) {
  const convertItem = (item) => {
    if (!item) {
      return null;
    }

    const elEconomy = item.getElementsByTagName('Economy')[0];
    const elApartmentNumber = item.getElementsByTagName('ApartmentNumber')[0];
    const elSize = item.getElementsByTagName('Size')[0];
    const elArea = item.getElementsByTagName('Area')[0];
    const elFloor = item.getElementsByTagName('Floor')[0];
    const blueprintImage = item.querySelector(
      'Image[category="Planlösningar"] Large'
    );

    const status = getHumanReadableItemStatus(item.getAttribute('saleStatus'));

    return {
      fasadId: item.getAttribute('id'),
      apartmentNumber: elApartmentNumber.textContent,
      status,
      rooms: parseFloat(elSize.getAttribute('rooms')),
      economy: {
        currency: elEconomy.getAttribute('currency'),
        price:
          status === 'Såld' || status === 'Bokad'
            ? null
            : parseInt(elEconomy.getAttribute('price'), 10),
        fee:
          status === 'Såld' || status === 'Bokad'
            ? null
            : parseInt(elEconomy.getAttribute('fee'), 10),
      },
      area: {
        size: parseInt(elArea.getAttribute('size'), 10),
        unit: elArea.getAttribute('unit'),
      },
      floor: parseInt(elFloor.textContent, 10),
      imageSrc: blueprintImage ? blueprintImage.getAttribute('path') : null,
      ref: item,
      building: elApartmentNumber.textContent.startsWith('4')
        ? 'Alten'
        : 'Basen',
    };
  };

  const items = xmlDocument.querySelectorAll("Item:not([masterId=''])");
  return items && items.length > 0 ? [...items].map(convertItem) : [];
}

export const getSvgData = (scene = 0, building = '') => {
  const index = parseInt(scene, 10);
  if (building === 'alten') {
    if (index === 1) {
      return alten1Svg;
    } else if (index === 2) {
      return alten2Svg;
    } else if (index === 3) {
      return alten3Svg;
    } else {
      return alten4Svg;
    }
  } else if (building === 'basen') {
    if (index === 1) {
      return basen1Svg;
    } else if (index === 2) {
      return basen2Svg;
    } else if (index === 3) {
      return basen3Svg;
    } else {
      return basen4Svg;
    }
  } else {
    return false;
  }
};

export function formatNumber(number) {
  return parseInt(number, 10).toLocaleString('fr');
}
