import React from 'react';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';
import { useModal } from '../contexts/ModalContext';

import './ModalSceneNav.scss';
import altenBuildingImg from '../assets/images/alten.png';
import basenBuildingImg from '../assets/images/basen.png';
import allowedRoutes from '../data/allowedRoutes.json';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '40px',
    transform: 'translate(-50%, -50%)',
  },
};

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export default function ModalSceneNav({ building = 'basen' }) {
  const className = `scene-menu scene-menu-${building}`;
  const { scenes } = allowedRoutes;

  const { isOpen, setIsOpen } = useModal();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const sceneNavItems = scenes.map((scene, index) => {
    return (
      <li className="scene-menu-item" key={index}>
        <NavLink
          to={`/byggnad/${building}/${scene}`}
          data-sceneIndex={scene}
        ></NavLink>
      </li>
    );
  });

  return (
    <>
      <button
        className="open-view-nav"
        onClick={isOpen ? closeModal : openModal}
      >
        {isOpen ? 'Stäng' : 'Byt vy'}
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
        contentLabel="Välj vy"
      >
        <div className="birdview">
          <ul className={className}>{sceneNavItems}</ul>
          <img
            src={building === 'alten' ? altenBuildingImg : basenBuildingImg}
            alt="Fågelvy av byggnaderna"
          />
          <button className="close-view-nav" onClick={closeModal} title="Stäng">
            <svg className="icon icon-cross2">
              <use xlinkHref="#icon-cross2"></use>
            </svg>
          </button>
        </div>
        <p>Klicka på kamera-ikonerna ovan för att byta vy.</p>
      </Modal>
    </>
  );
}
