import React, { createContext, useState, useContext } from 'react';

export const ModalContext = createContext([]);

export default function ModalProvider(props) {
  const [isOpen, setIsOpen] = useState(false);

  const values = {
    isOpen,
    setIsOpen,
  };

  return (
    <ModalContext.Provider value={values}>
      {props.children}
    </ModalContext.Provider>
  );
}

export const ModalConsumer = ModalContext.Consumer;
export const useModal = () => useContext(ModalContext);
