import React from 'react';
import './LoadingIndicator.scss';

export default function LoadingIndicator() {
  return (
    <div className="loading-wrapper" data-testid="loading-indicator">
      <div className="loading">
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
