import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ObjectItem from './ObjectItem';
import ObjectTable from './ObjectTable';
import { getSvgData } from '../helpers/helpers';

import './Scene.scss';

export function Scene({
  building,
  sceneIndex,
  items,
  svgData,
  handleActiceObject,
  activeObjectId,
  showStatus,
}) {
  // Map all the objects into its corresponding SVG path in the render
  const objects = svgData.children.map((item) => {
    const object = [...items]
      .filter((o) => {
        return o ? o.hasOwnProperty('apartmentNumber') : false;
      })
      .find((o) => {
        return o.apartmentNumber === item.attributes['data-name'];
      });
    if (!object) {
      return null;
    } else {
      return (
        <ObjectItem
          id={object.fasadId}
          key={object.fasadId}
          name={item.name}
          type={item.type}
          attributes={item.attributes}
          itemData={object}
          handleActiceObject={handleActiceObject}
          activeObjectId={activeObjectId}
        />
      );
    }
  });

  const imgSrc = `/images/${building}_${sceneIndex}.jpg`;

  return (
    <div
      className={showStatus ? 'show-status-for-objects Scene' : 'Scene'}
      data-testid="scene"
    >
      <svg
        viewBox={svgData.attributes.viewBox}
        className="scene-svg"
        preserveAspectRatio={
          svgData.attributes.preserveAspectRatio || 'xMidYMid'
        }
        data-testid="scene-svg"
      >
        {objects}
      </svg>
      <img src={imgSrc} alt="Rendering" data-testid="scene-background" />
    </div>
  );
}

export default function SceneContainer({ items }) {
  const [activeObjectId, setActiveObjectId] = useState(null);
  const [showStatus, setShowStatus] = useState(false);
  const { building, sceneIndex } = useParams();
  const svgData = getSvgData(sceneIndex, building);

  const handleActiveObject = (fasadId) => {
    setActiveObjectId(fasadId);
  };

  const objects = svgData.children
    .filter((item) => {
      return item.attributes.id || false;
    })
    .map((item) => {
      const object = [...items].find((o) => {
        return o.apartmentNumber === item.attributes['data-name'];
      });

      if (!object) {
        return null;
      } else {
        return object;
      }
    });

  const itemsInActiveBuilding = items.filter(({ apartmentNumber }) => {
    return building === 'basen'
      ? !apartmentNumber.startsWith('4')
      : apartmentNumber.startsWith('4');
  });

  const sceneNumber = parseInt(sceneIndex, 10);
  const nextSceneIndex = sceneNumber !== 4 ? sceneNumber + 1 : 1;
  const prevSceneIndex = sceneNumber !== 1 ? sceneNumber - 1 : 4;

  return (
    <>
      <div className="scene-container" data-testid="scene-container">
        <Scene
          sceneIndex={sceneIndex}
          building={building}
          items={objects}
          svgData={svgData}
          handleActiceObject={handleActiveObject}
          activeObjectId={activeObjectId}
          showStatus={showStatus}
        />
        <button
          onClick={() => {
            setShowStatus(!showStatus);
          }}
          className="toggle-status-button"
        >
          {showStatus ? 'Göm status' : 'Visa status'}
        </button>
        <Link
          to={`/byggnad/${building}/${prevSceneIndex}`}
          className="prev-scene"
          title="Byt till föregående vy"
        >
          <svg className="icon icon-chevron-left">
            <use xlinkHref="#icon-chevron-left"></use>
          </svg>
        </Link>
        <Link
          to={`/byggnad/${building}/${nextSceneIndex}`}
          className="next-scene"
          title="Byt till nästa vy"
        >
          <svg className="icon icon-chevron-right">
            <use xlinkHref="#icon-chevron-right"></use>
          </svg>
        </Link>
      </div>
      <ObjectTable
        items={objects}
        handleActiceObject={handleActiveObject}
        building={building}
        sceneIndex={sceneIndex}
        activeObjectId={activeObjectId}
        totalItems={itemsInActiveBuilding.length || items.length}
      />
    </>
  );
}
