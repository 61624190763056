import React from 'react';
import { useRouteMatch, NavLink } from 'react-router-dom';
import ModalSceneNav from './ModalSceneNav';

import './Navigation.scss';
import allowedRoutes from '../data/allowedRoutes.json';

export default function Navigation() {
  const { buildings, scenes } = allowedRoutes;

  const routes = buildings.map((building) => {
    return scenes.map((scene) => {
      return `/byggnad/${building}/${scene}`;
    });
  });

  // Route matching for the buildings & scenes
  const matchAltenRoute = useRouteMatch(routes[0]);
  const matchBasenRoute = useRouteMatch(routes[1]);

  return (
    <nav>
      <ul className="main-nav">
        <li
          className={
            matchAltenRoute ? 'main-nav-item active-building' : 'main-nav-item'
          }
        >
          <NavLink className="button" to="/byggnad/alten/1">
            Alten
          </NavLink>
        </li>
        <li
          className={
            matchBasenRoute ? 'main-nav-item active-building' : 'main-nav-item'
          }
        >
          <NavLink to="/byggnad/basen/4" className="button">
            Basen
          </NavLink>
        </li>
        <li className="main-nav-item">
          <NavLink to="/prislista/" className="button">
            Prislista
          </NavLink>
        </li>
      </ul>
      {matchBasenRoute && <ModalSceneNav building="basen" />}
      {matchAltenRoute && <ModalSceneNav building="alten" />}
    </nav>
  );
}
