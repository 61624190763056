import React from 'react';
import NoMatch from './NoMatch';
import BackLink from './BackLink';
import { formatNumber } from '../helpers/helpers';

import './SingleObject.scss';

export default function SingleObject(props) {
  const { fasadId, items } = props;

  if (!items) {
    return null;
  }

  // The items inside object price list
  const object = [...items].find((item) => {
    return item.fasadId === fasadId;
  });

  if (!object) {
    return <NoMatch />;
  }

  const price = object.economy.price
    ? formatNumber(object.economy.price)
    : null;
  const fee = object.economy.fee ? formatNumber(object.economy.fee) : null;

  return (
    <>
      <BackLink />
      <article className="SingleObject" data-testid="single-object">
        {object.imageSrc ? (
          <figure className="SingleObject-floorplan">
            <a
              href={object.imageSrc}
              target="_blank"
              rel="noopener noreferrer"
              title="Se hela bofaktabladet"
            >
              <img
                src={object.imageSrc}
                alt="Planlösning"
                data-testid="single-object-img-floorplan"
              />
            </a>
          </figure>
        ) : null}
        <div className="SingleObject-main">
          <h1 data-testid="single-object-apartmentnumber">
            {object.apartmentNumber}
          </h1>
          <ul className="SingleObject-summary">
            <li>
              Status: <strong>{object.status}</strong>
            </li>
            <li>
              Antal rum: <strong>{object.rooms} rum & kök</strong>
            </li>
            <li>
              Pris:{' '}
              <strong>
                {price ? `${price} ${object.economy.currency}` : '-'}
              </strong>
            </li>
            <li>
              Avgift:{' '}
              <strong>{fee ? `${fee} ${object.economy.currency}` : '-'}</strong>
            </li>
            <li>
              Storlek: <strong>{object.area.size} m²</strong>
            </li>
            <li>
              Våning: <strong>{object.floor}</strong>
            </li>
            <li>
              Hus: <strong>{object.building}</strong>
            </li>
          </ul>
          <p>
            <a
              href={object.imageSrc}
              target="_blank"
              rel="noopener noreferrer"
              title="Läs hela bofaktabladet"
            >
              Till bofaktabladet
            </a>
          </p>
        </div>
      </article>
    </>
  );
}
