import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { orderBy, capitalize } from 'lodash';
import { getSvgData, formatNumber } from '../helpers/helpers';
import { useModal } from '../contexts/ModalContext';
import BackLink from './BackLink';

import './ObjectTable.scss';

export function ObjectTableItem(props) {
  const { item, activeObjectId, handleActiceObject } = props;

  let classes = 'ObjectTableItem';

  if (item.fasadId === activeObjectId) {
    classes += ' active';
  }

  return (
    <tr
      className={classes}
      data-testid="object-table-item"
      onMouseEnter={() => {
        if (typeof handleActiceObject === 'function') {
          handleActiceObject(item.fasadId);
        }
      }}
      onMouseLeave={() => {
        if (typeof handleActiceObject === 'function') {
          handleActiceObject(null);
        }
      }}
    >
      <td>
        <Link to={`/objekt/${item.fasadId}`}>{item.apartmentNumber}</Link>
      </td>
      <td>
        <Link to={`/objekt/${item.fasadId}`}>{item.status}</Link>
      </td>
      <td>
        <Link to={`/objekt/${item.fasadId}`}>{item.rooms} rum</Link>
      </td>
      <td>
        <Link to={`/objekt/${item.fasadId}`}>
          {item.economy.price
            ? `${formatNumber(item.economy.price)} ${item.economy.currency}`
            : '-'}
        </Link>
      </td>
      <td>
        <Link to={`/objekt/${item.fasadId}`}>
          {item.economy.fee
            ? `${formatNumber(item.economy.fee)} ${item.economy.currency}`
            : '-'}
        </Link>
      </td>
      <td>
        <Link to={`/objekt/${item.fasadId}`}>{item.area.size} m²</Link>
      </td>
      <td>
        <Link to={`/objekt/${item.fasadId}`}>{item.floor}</Link>
      </td>
      <td className="building-cell">
        <Link to={`/objekt/${item.fasadId}`}>{item.building}</Link>
      </td>
    </tr>
  );
}

function SortButton(props) {
  return (
    <button
      onClick={props.sortCallback}
      data-sortkey={props.sortKey}
      data-testid="sort-button"
    >
      {props.label}
      <svg className="icon icon-chevrons-expand-vertical">
        <use xlinkHref="#icon-chevrons-expand-vertical"></use>
      </svg>
    </button>
  );
}

export default function ObjectTable({
  items = [],
  activeObjectId = null,
  handleActiceObject = null,
  building = null,
  sceneIndex = null,
  totalItems = 0,
}) {
  const svgData =
    sceneIndex && building ? getSvgData(sceneIndex, building) : null;

  const { setIsOpen } = useModal();

  const objects =
    svgData && typeof svgData.children !== 'undefined'
      ? svgData.children
          .filter((item) => {
            return item.attributes.id || false;
          })
          .map((item) => {
            return [...items]
              .filter((o) => {
                return o ? o.hasOwnProperty('apartmentNumber') : false;
              })
              .find((o) => {
                return o.apartmentNumber === item.attributes['data-name'];
              });
          })
      : items;

  const [sort, setSort] = useState('apartmentNumber');
  const [order, setOrder] = useState('desc');

  if (!objects) {
    return null;
  }

  // The items inside object price list
  const sortedItems = orderBy([...objects], [sort], [order]);
  const rows = [...sortedItems].map((item) => {
    return item ? (
      <ObjectTableItem
        key={item.fasadId}
        item={item}
        activeObjectId={activeObjectId}
        handleActiceObject={handleActiceObject}
      />
    ) : null;
  });

  const handleSort = (event) => {
    const { currentTarget } = event;
    setOrder(order === 'desc' ? 'asc' : 'desc');
    setSort(currentTarget.getAttribute('data-sortkey'));
  };

  return (
    <>
      {rows.length === totalItems && <BackLink />}
      <div className="table-wrapper">
        <header className="table-header">
          <h2>
            {rows.length === totalItems
              ? `Alla objekt i Alten & Basen`
              : `I denna vy visas ${
                  rows.length
                } av ${totalItems} objekt i ${capitalize(building)}`}
          </h2>
          {totalItems !== rows.length && (
            <p className="before-objects-table-helper">
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Byt vy
              </button>
            </p>
          )}
        </header>
        <div className="table-container">
          <table className="ObjectTable" data-testid="object-table">
            <thead className="ObjectsTableHead">
              <tr>
                <th>
                  <SortButton
                    sortCallback={handleSort}
                    sortKey="apartmentNumber"
                    label="LghNr"
                  />
                </th>
                <th>
                  <SortButton
                    sortCallback={handleSort}
                    sortKey="status"
                    label="Status"
                  />
                </th>
                <th>
                  <SortButton
                    sortCallback={handleSort}
                    sortKey="rooms"
                    label="Antal rum"
                  />
                </th>
                <th>
                  <SortButton
                    sortCallback={handleSort}
                    sortKey="economy.price"
                    label="Pris"
                  />
                </th>
                <th>
                  <SortButton
                    sortCallback={handleSort}
                    sortKey="economy.fee"
                    label="Avgift"
                  />
                </th>
                <th>
                  <SortButton
                    sortCallback={handleSort}
                    sortKey="area.size"
                    label="Storlek"
                  />
                </th>
                <th>
                  <SortButton
                    sortCallback={handleSort}
                    sortKey="floor"
                    label="Våning"
                  />
                </th>
                <th>
                  <SortButton
                    sortCallback={handleSort}
                    sortKey="building"
                    label="Hus"
                  />
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
        <div className="objects-in-render-info">
          {totalItems === rows.length ? (
            ``
          ) : (
            <>
              <Link to="/prislista">
                <strong>Se hela prislistan</strong>
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}
